import * as React from 'react'
import styles from './index.module.css'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'

const NotFoundPage = () => (
    <Layout>
        <Helmet>
            <title>Page not found | Dave Macaulay</title>
        </Helmet>
        <div className={styles.floating}>
            <h1>Page not found.</h1>
            <p>
                It's a sad day when the page you're requesting does not exist.
            </p>
            <p>
                <a href="/">Take me home.</a>
            </p>
        </div>
    </Layout>
)

export default NotFoundPage
